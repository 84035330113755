import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { Grid } from "@material-ui/core";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import Label from "@ui/components/Label";
import commonConfig from "config/commonConfig";

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable, divaExcelColumns } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const { setValue } = formMethods;

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.configurations;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const onVerify = (res) => {
    setValue("excelData", res || {});
  };

  useEffect(() => {
    values.code &&
      setValue(
        "uploadApiUrl",
        `${commonConfig.microservices.upload}${enums.models.dumps}/upload?code=${values.code}`
      );
  }, [values.code]);

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    {
      name: "test",
      label: "Upload Excel with Columns",
      fieldType: "upload",
      single: true,
      accept: "*",
      model: enums.models.dumps,
      onVerify: onVerify,
      required: !values.excelData?.columnsArray?.length,
    },
    {
      name: "code",
      label: "Configuration Code",
      hidden: !values.code,
    },
    {
      name: "uploadApiUrl",
      label: "Upload API URL",
      hidden: !values.code,
      copyToClipboard: true,
      xs: 8,
    },
    {
      name: "upload",
      label: "Upload Excel with Columns & Data",
      fieldType: "upload",
      single: true,
      hidden: !values.code,
      code: values.code,
      accept: "*",
      model: enums.models.dumps,
    },
  ];

  const links = [
    {
      name: "Configurations",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.name || "",
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      tooltip: "Edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Configurations"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />

      <MainSection
        title={`Excel Data Configuration
          (Uploaded Columns: ${values.excelData?.columnsArray?.length || 0})`}
      >
        {values.excelData?.columnsArray?.length ? (
          <Grid container spacing={2}>
            {divaExcelColumns.map(
              (col, i) =>
                col.name && (
                  <Grid item xs={4} key={i} style={{ marginBottom: "8px" }}>
                    {isEditable ? (
                      <RHAutoComplete
                        options={values.excelData?.columnsArray || []}
                        name={`configuration[${col.name}]`}
                        label={col.label}
                        required={col.required}
                      />
                    ) : (
                      <Label
                        label={col.label}
                        value={values.configuration?.[col.name]}
                      />
                    )}
                  </Grid>
                )
            )}
          </Grid>
        ) : (
          <h2>Upload Excel to continue...</h2>
        )}
      </MainSection>
    </>
  );
};

export default DetailHeader;

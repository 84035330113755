import React, { useState } from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, isAdmin, isSuperAdmin } from "@ui/Utils/helper";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    title: "Available Codes",
    render: (row) => (row?.availableCodes || []).join(", "),
  },
  {
    title: "Modified Date",
    field: "dateModified",
    render: (row) =>
      row?.dateModified
        ? formatDateToRead(row?.dateModified)
        : formatDateToRead(row?.dateCreated),
  },
  {
    title: "Modified By",
    render: (row) =>
      row?.modifiedBy?.firstName
        ? row?.modifiedBy?.firstName + " " + row?.modifiedBy?.lastName
        : row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const VendorCodesList = () => {
  const [hideCreate, setHideCreate] = useState(true); // used inside GeneralListPage
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const overRideRecords = (records) => {
    if (!records?.length && (isAdmin || isSuperAdmin)) {
      setHideCreate(false);
    }
    setData(records);
    return records;
  };

  const headerActions = [
    {
      label: "Create",
      iconName: "add",
      tooltip: "Create",
      hidden: !hideCreate || !data[0]?.code,
      onClick: () =>
        data[0]?.code &&
        navigate(
          "/" + enums.models["vendor-portal"].vendorCodes + `/${data[0]?.code}`
        ),
    },
  ];

  return (
    <GeneralListPage
      columns={columns}
      model={enums.models["vendor-portal"].vendorCodes}
      headerTitle="Vendor Codes"
      searchFields={["availableCodes"]}
      overRideRecords={overRideRecords}
      paginationMeta={{ pageSize: 5 }}
      headerActions={headerActions}
      hideAction={{
        delete: true,
        search: true,
        selection: true,
        create: hideCreate,
      }}
    />
  );
};

export default VendorCodesList;

import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  exportExcel,
  formatDateToRead,
  isVendor,
  uniqueArray,
  userDetails,
} from "@ui/Utils/helper";
import {
  alphabets,
  downloadExcelFile,
  excelAlignment,
  excelFont,
  getCellStyle,
  getExcelDropdown,
  sheetNames,
} from "@ui/Utils/excelImportHelpers";
import { bulkStockUpsert, getByCode } from "../api";
import enums from "helpers/enums";
import { getSingleRecord, search, verifyDataEngineImages } from "../api";
import _ from "lodash";

const chunkSize = 5000;

const defaultRowHeight = {
  header: 25,
  productHeader: 35,
};
const mainFieldsObj = _.cloneDeep(enums.productsImportKey);
delete mainFieldsObj.lessWeight;
// delete mainFieldsObj.netWeight; take netWeight from excel because if they export excel from Printing Station and import it in Bulk Excel Import, this column difference shouldn't be a problem.. Just take it in Excel, as you wont even care it backend because, its calculating and giving netWeight for every input in DE.
delete mainFieldsObj.stockStatus;

const columnsObj = {
  ...mainFieldsObj,
  ...enums.stockLevelField,
};

const columns = Object.keys(columnsObj)
  .filter((col) => !col?.match("Rate"))
  .map((key) => ({
    header: columnsObj[key],
    width: 20,
    key,
  }));

const diamondDetailsCols = [{ header: enums.productsImportKey.sku, width: 20 }];
diamondDetailsCols.push(
  ...Object.values(enums.diamondImportKey).map((header) => ({
    header,
    width: 20,
  }))
);

export const downloadProductsTemplate = async (configuration) => {
  try {
    configuration = { ...configuration };

    const workbook = new ExcelJS.Workbook();
    const mainSheet = workbook.addWorksheet(sheetNames.products);

    let purities = [],
      categories = [],
      subCategories = [],
      collectionLines = [],
      vendorCodes = [],
      invalidFlag = false;

    try {
      // Run all searches in parallel using Promise.all
      [purities, categories, subCategories, collectionLines] =
        await Promise.all([
          search(enums.models["data-engine"].purity).catch((_) => {
            invalidFlag = true;
            CustomEventEmitter.emit("alert_error", "Failed to fetch Purities.");
            return []; // Return empty array in case of error
          }),
          search(enums.models["data-engine"].category).catch((_) => {
            invalidFlag = true;
            CustomEventEmitter.emit(
              "alert_error",
              "Failed to fetch Categories."
            );
            return []; // Return empty array in case of error
          }),
          search(enums.models["data-engine"].subCategory).catch((_) => {
            CustomEventEmitter.emit(
              "alert_error",
              "Failed to fetch Subcategories."
            );
            return []; // Return empty array in case of error
          }),
          search(enums.models["data-engine"].collectionLines).catch((_) => {
            CustomEventEmitter.emit(
              "alert_error",
              "Failed to fetch Collection Lines."
            );
            return []; // Return empty array in case of error
          }),
        ]);

      // Handle undefined or empty arrays for each category
      if (!purities.length) {
        invalidFlag = true;
        CustomEventEmitter.emit(
          "alert_error",
          "Add Purities Master Data to Continue."
        );
      }
      if (!categories.length) {
        invalidFlag = true;
        CustomEventEmitter.emit(
          "alert_error",
          "Add Categories Master Data to Continue."
        );
      }
      // if (!subCategories.length) {
      //   invalidFlag = true;
      //   CustomEventEmitter.emit(
      //     "alert_error",
      //     "Add Subcategories Master Data to Continue."
      //   );
      // }
      // if (!collectionLines.length) {
      //   invalidFlag = true;
      //   CustomEventEmitter.emit(
      //     "alert_error",
      //     "Add Collection Lines Master Data to Continue."
      //   );
      // }
    } catch (err) {
      invalidFlag = true;
      console.log("An error occurred while fetching master data:", err);
    }

    // Return false if any invalid flag is set
    if (invalidFlag) {
      return false;
    }

    if (isVendor) {
      vendorCodes = userDetails.vendorCode;
    } else {
      vendorCodes =
        (await getSingleRecord(enums.models["vendor-portal"].vendorCodes))
          ?.availableCodes || [];
    }

    const filteredColumns = [..._.cloneDeep(columns)]
      .filter((col) =>
        Object.keys(enums.stockLevelField).find((sField) => sField === col.key)
          ? (configuration?.fields || []).find(
              (config) => config.name === col.key && config.checked
            )
          : true
      )
      .map((col) => {
        (configuration?.fields || []).find((field) => {
          if (field.priceField && field.name === col.key) {
            col.header += field.pricePerWeight
              ? " (perWeight)" // this is handled in productsImport function in frontend as static, not from enums
              : field.pricePerPiece
              ? " (perPiece)" // this is handled in productsImport function in frontend as static, not from enums
              : "";
            return true;
          }
        });

        return col;
      });

    addMainColumns(mainSheet, filteredColumns, {
      purities,
      categories,
      subCategories,
      collectionLines,
      vendorCodes,
    });

    if (configuration.diamondFields) {
      const diamondSheet = workbook.addWorksheet(sheetNames.diamondDetails);

      addMainColumns(diamondSheet, diamondDetailsCols, { diamond: true });
    }

    downloadExcelFile(
      workbook,
      sheetNames.products + " " + formatDateToRead(new Date())
    );
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

export const downloadMissingDesigns = async (notFoundProducts = []) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(sheetNames.missingImages);
    let excelRow = 1;

    sheet.getCell(`A${excelRow}`).value = "Designs Missing Images";
    excelRow++;

    for (let i = 0; i < notFoundProducts.length; i++) {
      sheet.getCell(`A${excelRow + i}`).value =
        notFoundProducts[i]?.styleNo || "";
    }

    downloadExcelFile(workbook, sheetNames.missingImages);
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

const insertInfo = async (sheet, excelRow = 1) => {
  try {
    const clientData =
      (await getByCode(enums.models.iam.clients, userDetails?.client?.code)) ||
      {};
    const generalInfo = {
      Client: clientData.name,
      Phone: clientData.phone,
      Email: clientData.email,
      Screen: sheetNames.products,
    };

    Object.keys(generalInfo).map((key) => {
      sheet.getCell(`A${excelRow}`).value = key;
      sheet.getCell(`B${excelRow}`).value = generalInfo[key];

      sheet.getCell(`A${excelRow}`).style = getCellStyle(
        excelFont.size.infoLabel,
        excelFont.color.infoLabel,
        excelFont.bold.infoLabel
      );

      sheet.getCell(`B${excelRow}`).style = getCellStyle(
        excelFont.size.infoValue,
        excelFont.color.infoValue,
        excelFont.bold.infoValue
      );
      excelRow++;
    });

    sheet.getColumn("A").width = excelAlignment.colWidth.infoLabel;
    sheet.getColumn("B").width = excelAlignment.colWidth.infoValue;

    sheet.getColumn("A").alignment = {
      wrapText: excelAlignment.body.wrap,
      vertical: excelAlignment.headers.vertical,
    };
    sheet.getColumn("B").alignment = {
      wrapText: excelAlignment.body.wrap,
      vertical: excelAlignment.headers.vertical,
    };
  } catch (err) {
    console.log(err);
  }
  return excelRow;
};

const addMainColumns = (
  sheet,
  columns,
  {
    diamond = false,
    purities,
    categories,
    subCategories,
    collectionLines,
    vendorCodes,
  } = {}
) => {
  try {
    sheet.getRow("1").height = defaultRowHeight.header;
    sheet.columns = columns.map((col) => ({
      header: col.header,
      key: col.key,
      width:
        col.header?.toString().length > 21
          ? 40
          : col.header?.toString().length > 17
          ? 30
          : 20,
    }));

    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
      const columnHeader = columns[colIndex].header;

      // Styling only HEADERS
      sheet.getCell(`${alphabets[colIndex] + 1}`).style = getCellStyle(
        excelFont.size.headers,
        excelFont.color.headers,
        excelFont.bold.headers
      );

      sheet.getColumn(`${alphabets[colIndex]}`).alignment = {
        wrapText: excelAlignment.body.wrap,
      };

      sheet.getCell(`'${alphabets[colIndex] + 1}'`).alignment = {
        vertical: excelAlignment.headers.vertical,
        horizontal: excelAlignment.headers.horizontal,
        wrapText: true,
      };

      switch (columnHeader) {
        case enums.productsImportKey.category:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(categories.map((ele) => ele.name))
          );
          break;
        case enums.productsImportKey.subCategory:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(subCategories.map((ele) => ele.name))
          );
          break;
        case enums.productsImportKey.collectionLine:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(collectionLines.map((ele) => ele.name))
          );
          break;

        case enums.productsImportKey.purity:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(purities.map((purity) => purity.value + " KT"))
          );
          break;

        case enums.stockLevelField.vendorCode:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(vendorCodes)
          );
          break;

        case enums.stockLevelField.screw:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(Object.values(enums.screws))
          );
          break;

        case enums.stockLevelField.setting:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(Object.values(enums.goldExtrasMaster.setting))
          );
          break;

        case enums.diamondImportKey.shape:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.shape)
          );
          break;
        case enums.diamondImportKey.color:
          if (diamond)
            sheet.dataValidations.add(
              `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
              getExcelDropdown([
                ...Object.values(enums.diamondColorOptions.isSolitaire),
                ...Object.values(enums.diamondColorOptions.nonSolitaire),
              ])
            );
          else
            sheet.dataValidations.add(
              `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
              getExcelDropdown([...Object.values(enums.colorOptions)])
            );
          break;
        case enums.diamondImportKey.clarity:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.clarity)
          );
          break;
        case enums.diamondImportKey.cut:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.cut)
          );
          break;
        case enums.diamondImportKey.polish:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.polish)
          );
          break;
        case enums.diamondImportKey.symmetry:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.symmetry)
          );
          break;
        case enums.diamondImportKey.fluorescenceIntensity:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.fluorescenceIntensity)
          );
          break;
        case enums.diamondImportKey.gradingLab:
          sheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(enums.diamondMasterData.gradingLab)
          );
          break;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleProductsImport = async (
  importedFile,
  setReadingFiles = () => {},
  setReadingRows = () => {},
  { replaceStocks = false }
) => {
  const responseArray = [],
    invalidExcelReport = {
      diamond: [],
      gold: [],
      goldColumns: [],
      diamondColumns: [],
    };

  let total = 0,
    created = 0,
    updated = 0,
    failed = 0,
    ignored = [];

  try {
    async function processImportedFile(importedFile) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(importedFile);
        reader.onloadend = async function (evt) {
          if (evt.target.readyState == FileReader.DONE) {
            const arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);

            const workbook = XLSX.read(array, { type: "array" });
            let columnsArray;
            const data = {};

            workbook.SheetNames.forEach((sheetName) => {
              columnsArray = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                {
                  header: 1,
                }
              )[0];
              var rowObj = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[sheetName]
              );
              var rowString = rowObj;
              data[sheetName] = rowString;
            });

            if (data[sheetNames.products]?.length > 0) {
              const excelData = data[sheetNames.products];
              const diamondSheetData = data[sheetNames.diamondDetails] || [];

              const skuCountMap = {};

              excelData.forEach((product) => {
                const sku = product.SKU;
                if (sku && skuCountMap[sku]) {
                  skuCountMap[sku]++;
                } else if (sku) {
                  skuCountMap[sku] = 1;
                }
              });

              const duplicateSKUs = Object.keys(skuCountMap).filter(
                (sku) => skuCountMap[sku] > 1
              );
              const missingSKUs = [];

              diamondSheetData.map((diamond, diamondRowNumber) => {
                if (!diamond.SKU?.toString().trim()) {
                  missingSKUs.push(diamondRowNumber + 2);
                }
              });

              if (duplicateSKUs.length || missingSKUs.length) {
                window.alert(
                  " ⚠️ ⚠️ ⚠️  Fix below issues to continue  ⚠️ ⚠️ ⚠️\n\n" +
                    `${
                      duplicateSKUs.length
                        ? `${sheetNames.products} Sheet! Duplicate SKUs: ${duplicateSKUs}\n`
                        : ""
                    }${
                      missingSKUs.length
                        ? `${sheetNames.diamondDetails} Sheet! Missing SKU Row Numbers: ${missingSKUs}`
                        : ""
                    }`
                );
              } else {
                const chunks = Math.ceil(excelData.length / chunkSize);
                let min = 0,
                  max = chunkSize;

                for (let i = 0; i < chunks; i++) {
                  let paginated = excelData.slice(min, max);

                  // removing perWeight and perPiece helper text from excel Columns
                  paginated = paginated.map((data) => {
                    Object.keys(data || {}).map((excelColumn) => {
                      if (
                        excelColumn.includes("(perWeight)") ||
                        excelColumn.includes("(perPiece)")
                      ) {
                        let cleanedExcelColumn =
                          excelColumn.split(" (perWeight)")[0];

                        cleanedExcelColumn =
                          cleanedExcelColumn.split(" (perPiece)")[0];

                        data[cleanedExcelColumn] = data[excelColumn];

                        delete data[excelColumn];
                      }
                    });

                    return data;
                  });

                  const diamondDetails = diamondSheetData.filter((d) =>
                    paginated.find(
                      (p) =>
                        p.SKU?.toString().trim() == d.SKU?.toString().trim()
                    )
                  );

                  try {
                    const res = await bulkStockUpsert(
                      enums.models["data-engine"].products,
                      {
                        data: paginated,
                        diamondDetails,
                        replaceStocks,
                      }
                    );

                    created += res.created;
                    updated += res.updated;
                    failed += res.failed;
                    ignored += res.ignored;

                    const modifiedInvalidRows = {
                      // As this is paginated chunk wise upload, exact row index is not known in backend
                      gold: {
                        // goldenRowNumber: ["Column Name"]
                      },
                      diamond: {
                        // diamondRowNumber: ["Column Name"]
                        ...(res.invalidData?.diamond || {}),
                      },
                    };
                    Object.keys(res.invalidData?.gold || {}).map(
                      (goldenRowNumber) => {
                        goldenRowNumber = Number(goldenRowNumber);

                        modifiedInvalidRows.gold[goldenRowNumber + min] =
                          res.invalidData.gold[goldenRowNumber];
                      }
                    );

                    // Invalid Excel report
                    // i. Cols
                    if (
                      !invalidExcelReport.goldColumns.length &&
                      res.invalidExcelReport?.stockLevelFields?.length
                    ) {
                      invalidExcelReport.goldColumns =
                        res.invalidExcelReport?.stockLevelFields;
                    }
                    if (
                      !invalidExcelReport.diamondColumns.length &&
                      res.invalidExcelReport?.diamondFields?.length
                    ) {
                      invalidExcelReport.diamondColumns =
                        res.invalidExcelReport?.diamondFields;
                    }

                    // ii. Data
                    if (res.invalidExcelReport?.gold?.length) {
                      invalidExcelReport.gold.push(
                        ...res.invalidExcelReport.gold
                      );
                    }

                    if (res.invalidExcelReport.diamond?.length) {
                      invalidExcelReport.diamond.push(
                        ...res.invalidExcelReport.diamond
                      );
                    }

                    responseArray.push({
                      ...res,
                      invalidData: modifiedInvalidRows,
                    });

                    setReadingRows(
                      `${min + paginated.length} / ${excelData.length}`
                    );

                    CustomEventEmitter.emit(
                      "alert_success",
                      `Imported: ${created + updated} / ${excelData.length}`
                    );

                    if (res.failed) {
                      CustomEventEmitter.emit(
                        "alert_error",
                        `${`Failed: ${failed}`}`
                      );
                    }

                    min += chunkSize;
                    max += chunkSize;
                  } catch (err) {
                    console.log(err);
                    CustomEventEmitter.emit(
                      "alert_error",
                      "Some rows failed in the middle..."
                    );
                  }
                }

                // Not required as now it gets synced in the API itself...
                // CustomEventEmitter.emit(
                //   "alert_success",
                //   `Import Completed. Syncing Images in progress...`
                // );
                // await syncImageToDesign({ checkAndSyncAll: true });

                total = excelData.length;
              }

              resolve(); // Resolve the promise when processing is complete
            } else {
              CustomEventEmitter.emit("alert_error", "No Data to Import");
              reject("No Data to Import");
            }
          }
        };
      });
    }

    // Example of using await:

    await processImportedFile(importedFile); // replace 'yourImportedFile' with the actual file input
    // Call the exampleUsage function whenever you want to process the file

    const f2fPayload = [];

    if (invalidExcelReport.gold.length) {
      f2fPayload.push({
        data: invalidExcelReport.gold,
        columns: invalidExcelReport.goldColumns.map((col) => ({
          title: col.label,
          field: col.label,
          width:
            col.label?.toString().length > 21
              ? 40
              : col.label?.toString().length > 17
              ? 30
              : 20,
          options: col.options ? col.options : undefined,
        })),
        sheetName: sheetNames.products,
      });
    }

    if (invalidExcelReport.diamond.length) {
      f2fPayload.push({
        data: invalidExcelReport.diamond,
        columns: invalidExcelReport.diamondColumns.map((col) => ({
          title: col.label,
          field: col.label,
          width:
            col.label?.toString().length > 21
              ? 40
              : col.label?.toString().length > 17
              ? 30
              : 20,
          options: col.options ? col.options : undefined,
        })),
        sheetName: sheetNames.diamondDetails,
      });
    }

    if (
      f2fPayload.length &&
      window.confirm(
        "💫 💫 💫  Failures Report Loaded  💫 💫 💫\n\n🎮 🎮 🎮  Download?"
      )
    ) {
      await exportExcel(f2fPayload, `Stock Failures ${new Date()}`, {
        raw: true,
      });
    }
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Import Failure");
  }
  setReadingFiles(false);
  return [...responseArray, { total, created, updated, failed, ignored }];
};

export const downloadProducts = async (products = []) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const infoSheet = workbook.addWorksheet(sheetNames.info);

    await insertInfo(infoSheet);

    const mainSheet = workbook.addWorksheet("Products");

    addMainColumns(mainSheet, columns);

    let excelRow = 2;

    products.map((prod) => {
      columns.map((col, colIndex) => {
        switch (col.header) {
          case "Category":
            mainSheet.getCell(alphabets[colIndex] + excelRow).value =
              prod?.category?.name;
            break;
          case "Style No":
            mainSheet.getCell(alphabets[colIndex] + excelRow).value =
              prod?.styleNo;
            break;
          case "Gross Weight":
            mainSheet.getCell(alphabets[colIndex] + excelRow).value =
              prod?.grossWeight;
            break;
          case "Net Weight":
            mainSheet.getCell(alphabets[colIndex] + excelRow).value =
              prod?.netWeight;
            break;
        }

        mainSheet.getCell(alphabets[colIndex] + excelRow).style = getCellStyle(
          excelFont.size.headers,
          excelFont.color.sectionHeading,
          excelFont.bold.headers
        );
      });

      mainSheet.getRow(excelRow).height = defaultRowHeight.productHeader;

      excelRow++;
      prod?.stockUnits &&
        prod.stockUnits.map((unit) => {
          columns.map((col, colIndex) => {
            switch (col.header) {
              case "SKU":
                mainSheet.getCell(alphabets[colIndex] + excelRow).value =
                  unit.code;
                break;
              case "Stock Status":
                mainSheet.getCell(alphabets[colIndex] + excelRow).value =
                  unit.status;
                break;
            }
          });

          excelRow++;
        });

      excelRow += 2;
    });

    downloadExcelFile(workbook, sheetNames.products + " Report");
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

// export const exportProducts = async (products = []) => {
//   try {
//     const workbook = new ExcelJS.Workbook();
//     const infoSheet = workbook.addWorksheet(sheetNames.info);

//     await insertInfo(infoSheet);

//     const mainSheet = workbook.addWorksheet("Products");

//     addMainColumns(mainSheet, columns);

//     mainSheet.dataValidations.add(
//       `${alphabets[7]}2:${alphabets[7]}999999`,
//       getExcelDropdown(Object.values(enums.stockStatus))
//     );

//     let excelRow = 2;

//     products.map((prod) => {
//       if (!prod?.stockUnits?.length) {
//         columns.map((col, colIndex) => {
//           switch (col.header) {
//             case "Category":
//               mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                 prod?.category?.name;
//               break;
//             case "Style No":
//               mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                 prod?.styleNo;
//               break;
//             case "Gross Weight":
//               mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                 prod?.grossWeight;
//               break;
//             case "Net Weight":
//               mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                 prod?.netWeight;
//               break;
//           }
//         });

//         excelRow++;
//       } else {
//         prod?.stockUnits &&
//           prod.stockUnits.map((unit) => {
//             columns.map((col, colIndex) => {
//               switch (col.header) {
//                 case "SKU":
//                   mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                     unit.code;
//                   break;
//                 case "Stock Status":
//                   mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                     unit.status;
//                   break;

//                 case "Category":
//                   mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                     prod?.category?.name;
//                   break;
//                 case "Style No":
//                   mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                     prod?.styleNo;
//                   break;
//                 case "Gross Weight":
//                   mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                     prod?.grossWeight;
//                   break;
//                 case "Net Weight":
//                   mainSheet.getCell(alphabets[colIndex] + excelRow).value =
//                     prod?.netWeight;
//                   break;
//               }
//             });

//             excelRow++;
//           });

//         excelRow++;
//       }
//     });

//     downloadExcelFile(workbook, sheetNames.products + " Report");
//   } catch (err) {
//     console.log(err);
//     CustomEventEmitter.emit("alert_error", "Something went wrong!");
//   }
// };

export const handleVerifyDataEngineImages = (
  importedFile,
  setReadingFiles = () => {}
) => {
  try {
    const reader = new FileReader();

    reader.readAsArrayBuffer(importedFile);
    reader.onloadend = async function (evt) {
      if (evt.target.readyState == FileReader.DONE) {
        const arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);

        const workbook = XLSX.read(array, { type: "array" });
        let columnsArray;
        const data = {};
        workbook.SheetNames.forEach((sheetName) => {
          columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          })[0];
          var rowObj = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          var rowString = rowObj;
          data[sheetName] = rowString;

          // if (columns?.find((key) => !columnsArray?.includes(key.title))) {
          //   return (validationSuccess = false);
          // }
        });

        if (data[sheetNames.products]?.length > 0) {
          const reqStyles = data[sheetNames.products].map(
            (prod) => prod?.[enums.productsImportKey.styleNo]
          );

          const uniqueDesigns = uniqueArray(reqStyles);

          const { found, notFound } = await verifyDataEngineImages(
            uniqueDesigns.map((styleNo) => ({ styleNo }))
          );

          window.alert(
            `Requested total: ${reqStyles.length}\nUnique Styles: ${
              uniqueDesigns.length
            }\nFound: ${found?.length}\nNot Found: ${
              notFound?.length
            }\nNot Found: ${notFound.map((prod) => prod.styleNo)}`
          );

          console.log(
            "Found: ",
            found?.map((prod) => prod?.styleNo),
            "\n\n\n",
            "Not Found: ",
            notFound?.map((prod) => prod?.styleNo)
          );
        } else {
          CustomEventEmitter.emit(
            "alert_error",
            "No Data to Import. Download Template and Try Again"
          );
        }

        setReadingFiles(false);
      }
    };
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Import Failure");
    setReadingFiles(false);
  }
};

import React from "react";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShopOutlinedIcon from "@mui/icons-material/ShopOutlined";
import ShopIcon from "@mui/icons-material/Shop";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import HailIcon from "@mui/icons-material/Hail";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import FilterDramaOutlinedIcon from "@mui/icons-material/FilterDramaOutlined";
import StarsIcon from "@mui/icons-material/Stars";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import FunctionsIcon from "@mui/icons-material/Functions";
import FestivalIcon from "@mui/icons-material/Festival";
import FestivalOutlinedIcon from "@mui/icons-material/FestivalOutlined";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import SportsHockeyIcon from "@mui/icons-material/SportsHockey";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
// ABHISHEK: Use this icon for Sync Feature

// Components
import HomePage from "pages/HomePage/HomePage";

// utils
import { isAdmin, isSuperAdmin, isVendor } from "@ui/Utils/helper";
import enums from "helpers/enums";
import VendorsList from "@ui/views/vendor-portal/Vendors/List/List";
import VendorDetail from "@ui/views/vendor-portal/Vendors/Detail/Detail";

import QuotationDetail from "@ui/views/vendor-portal/Quotation/Detail/Detail";
import DumpsList from "views/Dumps/List/List";
import SettingsList from "@ui/views/vendor-portal/Settings/List/List";
import QuotationsList from "@ui/views/vendor-portal/Quotation/List/List";
import ConfigurationDetail from "views/Configurations/Detail/Detail";
import OrdersList from "@ui/views/e-creatives/Orders/List/List";
import OrderDetail from "@ui/views/e-creatives/Orders/Detail/Detail";
import VendorCodesList from "@ui/views/vendor-portal/VendorCodes/List/List";
import VendorCodeDetail from "@ui/views/vendor-portal/VendorCodes/Detail/Detail";
import StockOrdersList from "@ui/views/e-creatives/StockOrders/List/List";
import StockOrderDetail from "@ui/views/e-creatives/StockOrders/Detail/Detail";
import CustomOrdersList from "@ui/views/e-creatives/CustomOrders/List/List";
import CustomOrderDetail from "@ui/views/e-creatives/CustomOrders/Detail/Detail";
import CatalogueQuotationsList from "@ui/views/e-creatives/CatalogueQuotations/List/List";
import CatalogueQuotationDetail from "@ui/views/e-creatives/CatalogueQuotations/Detail/Detail";
import LinkItOrdersList from "@ui/views/e-creatives/LinkIt/List/List";
import LinkItOrderDetail from "@ui/views/e-creatives/LinkIt/Detail/Detail";
import ExpiryLinkDetail from "@ui/views/e-creatives/LinkIt/ExpiryLinkDetail/Detail";
import ExhibitionQuotationsList from "@ui/views/e-creatives/ExhibitionQuotations/List/List";
import ProductDetail from "@ui/views/data-engine/Import/Products/Detail/Detail";
import ImportMenu from "@ui/views/data-engine/Import/List/index";
import ExhibitionQuotationDetail from "@ui/views/e-creatives/ExhibitionQuotations/Detail/Detail";

const itsStyle = { color: enums.colorTheme.primary };

const screenAccess = isAdmin || isSuperAdmin;

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Dashboard",
    path: enums.models.dashboard,
    icon: () => <DashboardOutlinedIcon />,
    selectedIcon: () => <DashboardIcon style={styles.selectedIcon} />,
    component: () => <HomePage />,
  },
  // {
  //   access: screenAccess || isVendor,
  //   title: "Data Hub",
  //   // titleStyle: itsStyle,
  //   path: enums.models.dataEntry,
  //   hideMenuByDefault: true,
  //   icon: () => (
  //     <SportsEsportsOutlinedIcon
  //     // style={itsStyle}
  //     />
  //   ),
  //   selectedIcon: () => (
  //     <SportsEsportsIcon
  //     // style={itsStyle}
  //     />
  //   ),
  //   component: () => (screenAccess || isVendor ? <ImportMenu /> : <HomePage />),
  // },
  {
    // only to create a route, not for Menu
    access: false,
    path: enums.models.bulkPrint,
    hideMenuByDefault: true,
    component: () => (screenAccess || isVendor ? <ImportMenu /> : <HomePage />),
  },
  {
    // only to create a route, not for Menu
    access: false,
    path: enums.models.excelImport,
    hideMenuByDefault: true,
    component: () => (screenAccess || isVendor ? <ImportMenu /> : <HomePage />),
  },
  {
    // only to create a route, not for Menu
    access: false,
    path: enums.models.imageUpload,
    hideMenuByDefault: true,
    component: () => (screenAccess || isVendor ? <ImportMenu /> : <HomePage />),
  },
  {
    access: screenAccess || isVendor,
    title: "Diva",
    titleStyle: itsStyle,
    path: enums.models.quotations,
    hideMenuByDefault: true,
    icon: () => <ShopOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <ShopIcon style={itsStyle} />,

    component: () =>
      screenAccess || isVendor ? <QuotationsList /> : <HomePage />,
  },

  {
    access: screenAccess || isVendor,
    title: "Stock Os",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].stockOrders,
    icon: () => <ShoppingBasketOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <ShoppingBasketIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () =>
      screenAccess || isVendor ? <StockOrdersList /> : <HomePage />,
  },
  {
    access: true,
    title: "Custom Os",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].customOrders,
    icon: () => <FunctionsOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <FunctionsIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => <CustomOrdersList />,
  },
  {
    access: screenAccess || isVendor,
    title: "Exhibit IT",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].exhibitionQuotations,
    icon: () => <FestivalOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <FestivalIcon style={itsStyle} />,
    component: () => <ExhibitionQuotationsList />,
  },

  {
    access: screenAccess || isVendor,
    title: "Link IT",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].linkItOrders,
    icon: () => <SportsHockeyIcon style={itsStyle} />,
    selectedIcon: () => <SportsHockeyIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => <LinkItOrdersList />,
  },

  {
    access: screenAccess || isVendor,
    title: "Quote IT",
    titleStyle: itsStyle,
    path: enums.models["e-creatives"].catalogueQuotations,
    icon: () => <RequestQuoteOutlinedIcon style={itsStyle} />,
    selectedIcon: () => <RequestQuoteIcon style={itsStyle} />,
    hideMenuByDefault: true,
    component: () => <CatalogueQuotationsList />,
  },
  {
    access: screenAccess || isVendor,
    title: "E-commerce",
    titleStyle: itsStyle,

    path: enums.models["e-creatives"].orders,
    icon: () => <ShoppingCartOutlinedIcon style={itsStyle} />,
    selectedIcon: () => (
      <ShoppingCartIcon style={{ ...styles.selectedIcon, ...itsStyle }} />
    ),
    component: () =>
      screenAccess || isVendor ? (
        <OrdersList hideTabs={{ exhibition: true }} />
      ) : (
        <HomePage />
      ),
  },

  {
    access: screenAccess,
    title: "Vendors",
    path: enums.models["vendor-portal"].users,
    icon: () => <HailIcon />,
    selectedIcon: () => <HailIcon style={styles.selectedIcon} />,
    component: () => (screenAccess ? <VendorsList /> : <HomePage />),
  },
  // {
  //   access: isAdmin || isSuperAdmin,
  //   title: "Configuration",
  //   path: enums.models.configurations,
  //   icon: () => <SettingsSuggestOutlinedIcon />,
  //   selectedIcon: () => <SettingsSuggestIcon style={styles.selectedIcon} />,
  //   component: () => <ConfigurationsList />,
  // },

  {
    access: screenAccess,
    title: "V Codes",
    path: enums.models.masterData,
    icon: () => <StarsIcon />,
    component: () => (screenAccess ? <VendorCodesList /> : <HomePage />),
  },
  {
    access: screenAccess,
    title: "Settings",
    path: enums.models.settings,
    icon: () => <SettingsSuggestOutlinedIcon />,
    selectedIcon: () => <SettingsSuggestIcon style={styles.selectedIcon} />,
    component: () => (screenAccess ? <SettingsList /> : <HomePage />),
  },
  {
    access: isSuperAdmin,
    title: "Dumps",
    path: enums.models.dumps,
    icon: () => <FilterDramaOutlinedIcon />,
    selectedIcon: () => <CloudCircleIcon style={styles.selectedIcon} />,
    component: () => (isSuperAdmin ? <DumpsList /> : <HomePage />),
  },
];

export const secondaryMenuRoutes = [
  {
    access: screenAccess,
    title: "Configuration",
    path: enums.models.configurations + "/:code",
    component: () => (screenAccess ? <ConfigurationDetail /> : <HomePage />),
  },
  {
    access: screenAccess || isVendor,
    title: "Diva",
    path: enums.models.quotations + "/:code",
    component: () =>
      screenAccess || isVendor ? <QuotationDetail /> : <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "Order",
    path: enums.models["e-creatives"].orders + "/:code",
    component: () =>
      screenAccess || isVendor ? <OrderDetail /> : <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "Stock Order",
    path: enums.models["e-creatives"].stockOrders + "/:code",
    component: () =>
      screenAccess || isVendor ? <StockOrderDetail /> : <HomePage />,
  },
  {
    access: true,
    title: "Custom Order",
    path: enums.models["e-creatives"].customOrders + "/:code",
    component: () => (true ? <CustomOrderDetail /> : <HomePage />),
  },
  {
    access: true,
    title: "Exhibit it",
    path: enums.models["e-creatives"].exhibitionQuotations + "/:code",
    component: () => (true ? <ExhibitionQuotationDetail /> : <HomePage />),
  },
  {
    access: screenAccess || isVendor,
    title: "LINK it",
    path: enums.models["e-creatives"].linkItOrders + "/:code",
    component: () =>
      screenAccess || isVendor ? <LinkItOrderDetail /> : <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "EXPIRE it",
    path: enums.models["e-creatives"].expiryLinks + "/:code",
    component: () =>
      screenAccess || isVendor ? <ExpiryLinkDetail /> : <HomePage />,
  },
  {
    access: true,
    title: "Quote it",
    path: enums.models["e-creatives"].catalogueQuotations + "/:code",
    component: () => (true ? <CatalogueQuotationDetail /> : <HomePage />),
  },

  {
    access: screenAccess,
    title: "Vendors",
    path: enums.models["vendor-portal"].users + "/:code",
    component: () => (screenAccess ? <VendorDetail /> : <HomePage />),
  },
  {
    access: screenAccess,
    title: "V Codes",
    path: enums.models["vendor-portal"].vendorCodes + "/:code",
    component: () => <VendorCodeDetail />,
  },
  {
    access: screenAccess || isVendor,
    title: "Products",
    path: enums.models.products + "/:code",
    component: () => <ProductDetail />,
  },
];

const styles = {
  selectedIcon: { color: "black", fontSize: 30 },
};

import React from "react";
import { Grid, Typography, Checkbox } from "@material-ui/core/index";
import Label from "@ui/components/Label";
import enums from "helpers/enums";
import settingsIcon from "@ui/assets/data-engine/assets/ComponentIcons/settings.png";
import SwitchesGroup from "./SwitchesGroup";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";

const Configuration = ({
  configuration,
  originalConfiguration,
  setConfiguration,
  originalFields = [],
}) => {
  // This component is not connected to react hook form..
  // const [values, setValues] = useState({ fields }); // Independant values X-D

  const renderMasterField = (
    field = {
      fieldType: "",
      dataType: "",
      label: "",
      name: "",
      apiUrl: "",
      apiBody: {},
      apiMethod: "",
    }
  ) => {
    const fieldElement =
      configuration.fields?.find((cf) => cf.name === field.name) || {};
    const originalFieldElement =
      originalConfiguration.fields?.find((cf) => cf.name === field.name) || {};

    const disableField =
      !!originalFieldElement.checked && originalConfiguration.code;

    switch (field.fieldType) {
      case enums.fieldTypes.textField:
      default:
        return (
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Checkbox
                checked={!!fieldElement.checked}
                disabled={disableField}
                color={"secondary"}
                onChange={(event) => {
                  const fieldMatched = (configuration?.fields || []).find(
                    (existingField) => {
                      if (existingField.name === field.name) {
                        return true;
                      }
                    }
                  );

                  if (!fieldMatched) {
                    configuration.fields = configuration.fields || [];
                    configuration.fields.push(field);
                  }

                  const tempFields = configuration.fields.map(
                    (existingField) => {
                      if (existingField.name === field.name) {
                        const tempObj = {
                          ...existingField,
                          checked: event.target.checked,
                        };
                        if (field.weightField) {
                          tempObj.includeForLessWeight = event.target.checked;
                        }

                        if (field.priceField) {
                          tempObj.includeForTotalExtrasPrice =
                            event.target.checked;

                          if (event.target.checked) {
                            tempObj.pricePerWeight = event.target.checked;
                          } else {
                            tempObj.pricePerWeight = event.target.checked;
                            tempObj.pricePerPiece = event.target.checked;
                          }
                        }

                        return tempObj;
                      }
                      return existingField;
                    }
                  );

                  setConfiguration({
                    ...configuration,
                    fields: tempFields,
                  });
                }}
              />
            </Grid>

            <Grid item xs={10} style={{ paddingTop: "24px" }}>
              <Label
                noColon={true}
                label={field.label}
                value={fieldElement.checked}
              />
            </Grid>

            {field.weightField ? (
              <>
                <Grid item xs={2}>
                  <Checkbox
                    checked={!!fieldElement.includeForLessWeight}
                    disabled={disableField}
                    color={"secondary"}
                    onChange={(event) => {
                      const fieldMatched = (configuration.fields || []).find(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            return true;
                          }
                        }
                      );

                      if (!fieldMatched) {
                        configuration.fields = configuration.fields || [];
                        configuration.fields.push(field);
                      }

                      const tempFields = configuration.fields.map(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            const tempObj = {
                              ...existingField,
                              includeForLessWeight: event.target.checked,
                            };
                            if (event.target.checked) tempObj.checked = true;

                            return tempObj;
                          }
                          return existingField;
                        }
                      );

                      setConfiguration({
                        ...configuration,
                        fields: tempFields,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={10} style={{ paddingTop: "24px" }}>
                  <Label
                    noColon={true}
                    label={"Include for Less Weight"}
                    value={fieldElement.includeForLessWeight}
                  />
                </Grid>
              </>
            ) : field.priceField ? (
              <>
                {/* Include for totalExtrasPrice */}
                <Grid item xs={2}>
                  <Checkbox
                    checked={!!fieldElement.includeForTotalExtrasPrice}
                    disabled={disableField}
                    color={"secondary"}
                    onChange={(event) => {
                      const fieldMatched = (configuration.fields || []).find(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            return true;
                          }
                        }
                      );

                      if (!fieldMatched) {
                        configuration.fields = configuration.fields || [];
                        configuration.fields.push(field);
                      }

                      const tempFields = configuration.fields.map(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            const tempObj = {
                              ...existingField,
                              includeForTotalExtrasPrice: event.target.checked,
                            };
                            if (event.target.checked) {
                              tempObj.checked = true;

                              if (
                                !tempObj.pricePerPiece &&
                                !tempObj.pricePerWeight
                              )
                                tempObj.pricePerWeight = event.target.checked;
                            }
                            //  else {
                            //   // tempObj.pricePerWeight = event.target.checked;
                            //   // tempObj.pricePerPiece = event.target.checked;
                            // }

                            return tempObj;
                          }
                          return existingField;
                        }
                      );

                      setConfiguration({
                        ...configuration,
                        fields: tempFields,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={10} style={{ paddingTop: "24px" }}>
                  <Label
                    noColon={true}
                    label={"Include for Total Extras Price"}
                    value={fieldElement.includeForTotalExtrasPrice}
                  />
                </Grid>

                <Grid item xs={2}>
                  {/* per weight */}
                  <Checkbox
                    checked={!!fieldElement.pricePerWeight}
                    disabled={disableField}
                    color={"secondary"}
                    onChange={(event) => {
                      const fieldMatched = (configuration.fields || []).find(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            return true;
                          }
                        }
                      );

                      if (!fieldMatched) {
                        configuration.fields = configuration.fields || [];
                        configuration.fields.push(field);
                      }

                      const tempFields = configuration.fields.map(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            const tempObj = {
                              ...existingField,
                              pricePerWeight: event.target.checked,
                            };
                            if (event.target.checked) {
                              tempObj.checked = true;
                              // tempObj.includeForTotalExtrasPrice = true;

                              tempObj.pricePerPiece = false;
                            } else {
                              tempObj.pricePerPiece = true;
                            }
                            return tempObj;
                          }
                          return existingField;
                        }
                      );

                      setConfiguration({
                        ...configuration,
                        fields: tempFields,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={10} style={{ paddingTop: "24px" }}>
                  <Label
                    noColon={true}
                    label={"Calculate by Weight"}
                    value={fieldElement.pricePerWeight}
                  />
                </Grid>

                {/* per piece */}
                <Grid item xs={2}>
                  <Checkbox
                    checked={!!fieldElement.pricePerPiece}
                    disabled={disableField}
                    color={"secondary"}
                    onChange={(event) => {
                      const fieldMatched = (configuration.fields || []).find(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            return true;
                          }
                        }
                      );

                      if (!fieldMatched) {
                        configuration.fields = configuration.fields || [];
                        configuration.fields.push(field);
                      }

                      const tempFields = configuration.fields.map(
                        (existingField) => {
                          if (existingField.name === field.name) {
                            const tempObj = {
                              ...existingField,
                              pricePerPiece: event.target.checked,
                            };
                            if (event.target.checked) {
                              tempObj.checked = true;
                              // tempObj.includeForTotalExtrasPrice = true;

                              tempObj.pricePerWeight = false;
                            } else {
                              tempObj.pricePerWeight = true;
                            }
                            return tempObj;
                          }
                          return existingField;
                        }
                      );

                      setConfiguration({
                        ...configuration,
                        fields: tempFields,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={10} style={{ paddingTop: "24px" }}>
                  <Label
                    noColon={true}
                    label={"Calculate by Piece"}
                    value={fieldElement.pricePerPiece}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={2}>
              {field.weightField ? (
                <Checkbox
                  checked={!!fieldElement.inputInCts}
                  disabled={disableField}
                  color={"secondary"}
                  onChange={(event) => {
                    const fieldMatched = (configuration.fields || []).find(
                      (existingField) => {
                        if (existingField.name === field.name) {
                          return true;
                        }
                      }
                    );

                    if (!fieldMatched) {
                      configuration.fields = configuration.fields || [];
                      configuration.fields.push(field);
                    }

                    const tempFields = configuration.fields.map(
                      (existingField) => {
                        if (existingField.name === field.name) {
                          const tempObj = {
                            ...existingField,
                            inputInCts: event.target.checked,
                          };
                          if (event.target.checked) tempObj.checked = true;
                          return tempObj;
                        }
                        return existingField;
                      }
                    );

                    setConfiguration({
                      ...configuration,
                      fields: tempFields,
                    });
                  }}
                />
              ) : null}
            </Grid>

            <Grid item xs={10} style={{ paddingTop: "24px" }}>
              {field.weightField ? (
                <Label
                  noColon={true}
                  label={"Input in CTS"}
                  value={fieldElement.inputInCts}
                />
              ) : null}
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Grid container spacing={6}>
      <ScrollTopButton />

      <Grid item container xs={12}>
        <Grid item xs={12} container justify="center">
          <img
            src={settingsIcon}
            alt="settings"
            style={{
              height: "40px",
              width: "40px",
              marginRight: "24px",
              // float: "left",
            }}
          />

          <Typography
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              paddingTop: "-24px",
            }}
          >
            Configuration
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <pre style={{ textAlign: "center" }}>{`Setup extra fields`}</pre>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <SwitchesGroup
          setConfiguration={setConfiguration}
          configuration={configuration}
        />
      </Grid>
      {originalFields.map((field, fieldIndex) => (
        <Grid item xs={3} key={fieldIndex}>
          {renderMasterField(field)}

          <hr color="lightgrey" />
        </Grid>
      ))}

      {/* <Grid item xs={3}>
        Add more
      </Grid> */}
    </Grid>
  );
};

export default Configuration;

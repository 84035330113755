import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Grid } from "@material-ui/core/index";
import { MyAutocomplete } from "@ui/MuiComponents/index";
import enums from "helpers/enums";

export default function SwitchesGroup({ setConfiguration, configuration }) {
  const [oneTimeFlag, setOneTimeFlag] = React.useState(false);
  const [originalConfig, setOriginalConfig] = React.useState({});

  React.useEffect(() => {
    if (!oneTimeFlag) {
      setOriginalConfig(configuration);
      setOneTimeFlag(true);
    }
  }, [configuration, oneTimeFlag]);

  const handleChange = (event) => {
    setConfiguration({
      ...configuration,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={configuration.autoSKU}
                  onChange={handleChange}
                  name="autoSKU"
                  // sx={{
                  //   "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  //     color: "red"
                  //   },

                  //   "&.MuiSwitch-root .Mui-checked": {
                  //     color: "green"
                  //   }
                  // }}
                />
              }
              label="Auto Generate SKU Number"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={configuration.autoStyleNo}
                  onChange={handleChange}
                  name="autoStyleNo"
                  // sx={{
                  //   "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  //     color: "red",
                  //     backgroundColor: 'blue'
                  //   },

                  //   "&.MuiSwitch-root .Mui-checked": {
                  //     color: "green"
                  //   }
                  // }}
                />
              }
              label="Auto Generate Design Number"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={configuration.diamondFields}
                  onChange={handleChange}
                  disabled={
                    originalConfig.diamondFields
                      ? configuration.diamondFields && configuration.code
                      : false
                  }
                  name="diamondFields"
                  // sx={{
                  //   "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  //     color: "red",
                  //     backgroundColor: 'blue'
                  //   },

                  //   "&.MuiSwitch-root .Mui-checked": {
                  //     color: "green"
                  //   }
                  // }}
                />
              }
              label="Standard Diamond Details"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <MyAutocomplete
          options={[enums.stockStatus.staged, enums.stockStatus.inventory]}
          name="defaultStockStatus"
          label="Default Stock Status"
          disabled={configuration.code}
          value={configuration.defaultStockStatus || ""}
          disableClearable={true}
          onChange={(_, v) =>
            setConfiguration({
              ...configuration,
              defaultStockStatus: v,
            })
          }
        />
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core/index";
import ProductDetail from "@ui/views/data-engine/Import/Products/Detail/Detail";
import BulkUpload from "./BulkExcelUpload";
import BulkImageUpload from "./BulkImageUpload";
import enums from "helpers/enums";
import dataEntryIcon from "@ui/assets/data-engine/assets/ComponentIcons/dataEntry.png";
import bulkPrintIcon from "@ui/assets/data-engine/assets/ComponentIcons/bulkPrint.png";
import bulkImageUploadIcon from "@ui/assets/data-engine/assets/ComponentIcons/bulkImageUpload.png";
import productExcelUploadIcon from "@ui/assets/data-engine/assets/ComponentIcons/productExcelUpload.png";
import { Paper } from "@material-ui/core/index";
import ProductsList from "../Products/List/List";
import { useNavigate, useLocation } from "react-router-dom";
import useIsMobile from "@ui/Hooks/useIsMobile";

const dataEntry = "Data Entry",
  bulkPrint = "Bulk Print",
  productExcelUpload = "Product Excel Upload",
  bulkImageUpload = "Bulk Image Upload";

const styles = {
  subMenu: {
    height: 124,
    width: 120,
    marginTop: "50px",
    cursor: "pointer",
    transition: "transform 0.3s ease",
  },
  selectedSubMenu: {
    height: 124,
    width: 120,
    marginTop: "50px",
    cursor: "pointer",
    backgroundColor: "white",
    padding: "24px",
    transition: "transform 0.3s ease",
    transform: "scale(1.1)",
  },
};

const ImportMenu = () => {
  const [selectedSubMenu, setSelectedSubMenu] = useState(dataEntry);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  useEffect(() => {
    const url = location.pathname;

    if (url.match(new RegExp(enums.models.bulkPrint, "i"))) {
      setSelectedSubMenu(bulkPrint);
    } else if (url.match(new RegExp(enums.models.excelImport, "i"))) {
      setSelectedSubMenu(productExcelUpload);
    } else if (url.match(new RegExp(enums.models.imageUpload, "i"))) {
      setSelectedSubMenu(bulkImageUpload);
    } else {
      setSelectedSubMenu(dataEntry);
    }
  }, [location]);

  const renderSubMenu = () => {
    const url = location.pathname;

    if (url.match(new RegExp(enums.models.bulkPrint, "i"))) {
      return <ProductsList />;
    } else if (url.match(new RegExp(enums.models.excelImport, "i"))) {
      return <BulkUpload />;
    } else if (url.match(new RegExp(enums.models.imageUpload, "i"))) {
      return <BulkImageUpload />;
    } else {
      return <ProductDetail onCreate={true} />;
    }
  };

  const SubMenuIcon = ({ icon, alt, style }) => {
    const defaultStyle = {
      height: 124,
      width: 120,
      marginTop: "50px",
      cursor: "pointer",
      transition: "transform 0.3s ease",
    };

    const hoverStyle = {
      transform: "scale(1.1)",
    };

    const combinedStyle = { ...defaultStyle, ...style };

    return (
      <img
        src={icon}
        alt={alt}
        style={{ ...combinedStyle }}
        onMouseEnter={(e) => {
          e.target.style.transform = "scale(1.2)";
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = "scale(1)";
        }}
      />
    );
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        background: `linear-gradient(180deg, #E1F1FF 23.44%, #FFFCF6 100%)`,
      }}
    >
      {isMobile ? null : (
        <Grid item xs={2} container>
          <Grid>
            <Grid
              item
              xs={2}
              container
              style={{ paddingLeft: "48px" }}
              justify="flex-start"
            >
              <div onClick={() => navigate("/" + enums.models.dataEntry)}>
                <SubMenuIcon
                  alt={dataEntry}
                  icon={dataEntryIcon}
                  style={
                    selectedSubMenu === dataEntry
                      ? styles.selectedSubMenu
                      : styles.subMenu
                  }
                />
              </div>

              <div onClick={() => navigate("/" + enums.models.bulkPrint)}>
                <SubMenuIcon
                  alt={bulkPrint}
                  icon={bulkPrintIcon}
                  style={
                    selectedSubMenu === bulkPrint
                      ? styles.selectedSubMenu
                      : styles.subMenu
                  }
                />
              </div>
              <div onClick={() => navigate("/" + enums.models.excelImport)}>
                <SubMenuIcon
                  alt={productExcelUpload}
                  icon={productExcelUploadIcon}
                  style={
                    selectedSubMenu === productExcelUpload
                      ? styles.selectedSubMenu
                      : styles.subMenu
                  }
                />
              </div>
              <div onClick={() => navigate("/" + enums.models.imageUpload)}>
                <SubMenuIcon
                  alt={bulkImageUpload}
                  icon={bulkImageUploadIcon}
                  style={
                    selectedSubMenu === bulkImageUpload
                      ? styles.selectedSubMenu
                      : styles.subMenu
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={isMobile ? 12 : 10}>
        <Paper
          style={{
            padding: "8px",
            background: `rgba(255, 255, 255, 0.45)`,
            boxShadow: `0px 0.75385px 5.65388px rgba(0, 0, 0, 0.15)`,
            borderRadius: `10px`,
          }}
        >
          {renderSubMenu()}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ImportMenu;

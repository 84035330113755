export const divaExcelColumns = [
  { label: "RFID Tag", name: "rfidTag", required: true },
  { label: "SKU Number", name: "skuNumber", required: true },
  { label: "Design Number", name: "designNumber", required: true },
  { label: "Gross Weight", name: "grossWeight", required: true },
  { label: "Net Weight", name: "netWeight", required: true },
  { label: "Item Type", name: "itemType", required: true },
  { label: "Item Category", name: "itemCategory", required: true },
  { label: "Metal Type", name: "metalType", required: true },
  { label: "Metal Purity", name: "metalPurity", required: true },

  { label: "Image Name", name: "imageName" },
  { label: "Collection Line", name: "collectionLine" },
  { label: "Total Stone Weight", name: "totalStoneWeight" },
  { label: "Stone Weight", name: "stoneWeight" },
  { label: "Location", name: "location" },
  { label: "Owner", name: "owner" },
  { label: "Metal Weight", name: "metalWeight" },
  { label: "Total Diamond Weight", name: "totalDiamondWeight" },
  { label: "Item Status", name: "itemStatus" },
  { label: "Sales Man Name", name: "salesManName" },
  { label: "Size", name: "size" },
  { label: "Center Stone Type", name: "centerStoneType" },
  { label: "Center Stone Shape", name: "centerStoneShape" },
  { label: "Center Stone Size", name: "centerStoneSize" },
  { label: "Center Stone Quality", name: "centerStoneQuality" },
  { label: "Center Stone Color", name: "centerStoneColor" },
  { label: "Center Stone Weight", name: "centerStoneWeight" },
  { label: "Center Stone Certificate", name: "centerStoneCertificate" },
  { label: "Center Stone Cut", name: "centerStoneCut" },
  { label: "Stone Type", name: "stoneType" },
  { label: "Stone Shape", name: "stoneShape" },
  { label: "Stone Size", name: "stoneSize" },
  { label: "Stone Quality", name: "stoneQuality" },
  { label: "Stone Color", name: "stoneColor" },
  { label: "Stone Count", name: "stoneCount" },
  { label: "Stone Certificate", name: "stoneCertificate" },
  { label: "Stone Cut", name: "stoneCut" },
  { label: "Total Stone Count", name: "totalStoneCount" },
  { label: "Finding Type", name: "findingType" },
  { label: "Finding Metal", name: "findingMetal" },
  { label: "Finding Weight", name: "findingWeight" },
  { label: "Finding Metal Purity", name: "findingMetalPurity" },
  { label: "Gold Lock", name: "goldLock" },
  { label: "Silver Lock", name: "silverLock" },
  { label: "Platinum Lock", name: "platinumLock" },
  { label: "Cost Price", name: "costPrice" },
  { label: "Fob Price", name: "fobPrice" },
  { label: "Selling Price", name: "sellingPrice" },
  { label: "Metal Price", name: "metalPrice" },
  { label: "Diamond Price", name: "DiamondPrice" },
  { label: "Stone Price", name: "stonePrice" },
  { label: "Labor Price", name: "laborPrice" },
  { label: "Labor Charges Per Gram", name: "laborChargesPerGram" },
  { label: "Set Id", name: "setId" },
  { label: "Set Image Name", name: "setImageName" },
  { label: "Company", name: "company" },
  { label: "Counter Number", name: "counterNumber" },
  { label: "Counter Description", name: "counterDescription" },
  { label: "Tray Number", name: "trayNumber" },
  { label: "Tray Description", name: "trayDescription" },
  { label: "Customer", name: "customer" },
  { label: "Kundan Weight", name: "kundanWeight" },
  { label: "Kundan Pcs", name: "kundanPcs" },
  { label: "Meena Weight", name: "meenaWeight" },
  { label: "Remarks", name: "remarks" },
  { label: "Reserved 1", name: "reserved1" },
  { label: "Reserved 2", name: "reserved2" },
  { label: "Reserved 3", name: "reserved3" },
  { label: "Reserved 4", name: "reserved4" },
  { label: "Reserved 5", name: "reserved5" },
  { label: "Reserved 6", name: "reserved6" },
  { label: "Reserved 7", name: "reserved7" },
];

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { divaExcelColumns } from "../api";

const configurationObj = {};

divaExcelColumns.map((col) => {
  if (col.required) {
    configurationObj[col.name] = yup.string().required("Required");
  }
});

const validationSchema = yup
  .object()
  .shape({ configuration: yup.object().shape(configurationObj) });

const entitySchema = {
  name: "",
  products: [],
};

const ConfigurationDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const model = enums.models.configurations;

  const params = useParams();

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      if (!values._id) {
        const res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        const res = await updateRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      }
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
        divaExcelColumns={divaExcelColumns}
      />
    </FormProvider>
  );
};

export default ConfigurationDetail;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import Label from "@ui/components/Label";
import { formatNumberToRead, roundOff } from "@ui/Utils/helper";
import "./imageResponseUI.css";

const FacebookCircularProgress = ({ value }) => (
  <CircularProgress
    variant={value ? "determinate" : ""}
    value={value}
    size={40}
  />
);

const BorderLinearProgress = ({ value }) => (
  <LinearProgress variant="determinate" value={value} />
);

const UploadStats = ({
  progress,
  eta,
  totalTimeTaken,
  totalSize,
  display,
  plannedETA,
  subscription,
  firstChunk,
}) => {
  const [countdown, setCountdown] = useState(0);
  const [finalETA, setFinalETA] = useState("");

  const [firstETA, setFirstETA] = useState("");
  const [firstChunkStartTime, setFirstChunkStartTime] = useState(null);

  // Capture start time when firstChunk is set
  useEffect(() => {
    if (firstChunk) {
      setFirstChunkStartTime(Date.now());
    }
  }, [firstChunk]);

  // Handle intervals
  useEffect(() => {
    let intervalId;

    if (firstChunk) {
      intervalId = setInterval(() => {
        setFinalETA(
          `${formatTime(parseInt((Date.now() - firstChunkStartTime) / 1000))}`
        );
      }, 1000);
    } else if (eta) {
      const firstElapsedTime = firstChunkStartTime
        ? (Date.now() - firstChunkStartTime) / 1000
        : 0;

      setCountdown(Number(eta));
      setFirstETA(Number(eta));

      intervalId = setInterval(() => {
        setCountdown((prev) => (prev || eta) - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [firstChunk, eta, firstChunkStartTime]);

  // Update finalETA based on countdown
  useEffect(() => {
    if (!firstChunk && !plannedETA && countdown >= 0) {
      setFinalETA(formatTime(countdown));
    }
  }, [countdown, firstChunk]);

  // If plannedETA is received, update finalETA
  useEffect(() => {
    if (plannedETA) {
      setFinalETA(
        isNaN(plannedETA) ? plannedETA : formatTime(plannedETA + firstETA)
        //   : formatTime(plannedETA + (Date.now() - firstChunkStartTime) / 1000) //
      );
    }
  }, [plannedETA]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += `${hours} h `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes} m `;
    }
    formattedTime += `${seconds} s`;

    return formattedTime.trim();
  };

  return (
    <Box
      sx={{ flexGrow: 1, margin: "20px", marginTop: 0 }}
      className={plannedETA ? "fade-in" : "pulse"}
    >
      <Card sx={{ padding: "20px" }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <FacebookCircularProgress value={progress} />
              <Typography variant="h6">{`${Math.round(progress)}%`}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Label
                    label="Files"
                    valueStyle={{ fontSize: "12px" }}
                    value={
                      display.total ? formatNumberToRead(display.total) : "?"
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Label
                    labelStyle={{ textAlign: "center" }}
                    valueStyle={{ textAlign: "center", fontSize: "12px" }}
                    label="Size"
                    // noColon={true}
                    value={
                      totalSize > 1024
                        ? roundOff(totalSize / 1024) + " GB"
                        : totalSize
                        ? roundOff(totalSize) + " MB"
                        : "?"
                    }
                  />
                </Grid>

                <Grid item xs={3} container>
                  <Grid item xs={firstChunk ? 6 : 12}>
                    <Label
                      label={
                        <div
                          style={{
                            width: "50px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: firstChunk ? "orange" : "#3498db",
                            color: "white",
                            textAlign: "center",
                            paddingTop: "8px",
                            fontWeight: "bold",
                          }}
                        >
                          ETA
                        </div>
                      }
                      noColon={true}
                      value={finalETA}
                      valueStyle={{
                        fontWeight: "bold",
                        color: firstChunk ? "orange" : "#3498db",
                        textAlign: "center",
                        // marginLeft: "12px",
                      }}
                    />
                  </Grid>

                  {firstChunk ? (
                    <Grid item xs={6} style={{ cursor: "wait" }}>
                      <div class="loader">
                        <div class="loader-inner">
                          <Tooltip title="Estimating ETA">
                            <span class="loader-numbers">?</span>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={3}>
                  <Label
                    label={
                      <div
                        style={{
                          width: "70px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#3498db",
                          color: "white",
                          textAlign: "center",
                          paddingTop: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        Actual
                      </div>
                    }
                    noColon={true}
                    valueStyle={{
                      color: "#3498db",
                      fontWeight: "bold",
                      //   marginLeft: "22px",
                      textAlign: "center",
                    }}
                    tooltip={"Wait for itt..."}
                    value={totalTimeTaken ? formatTime(totalTimeTaken) : "?"}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Label
                    labelStyle={{ textAlign: "left" }}
                    valueStyle={{ textAlign: "left", fontSize: "12px" }}
                    label="Images"
                    value={
                      display.successTotal?.de ? display.successTotal?.de : "?"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  {subscription.cortex ? (
                    <Label
                      label="AI"
                      valueStyle={{ fontSize: "12px" }}
                      value={
                        display.successTotal?.cortex
                          ? display.successTotal?.cortex
                          : "?"
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: "20px" }}>
            <BorderLinearProgress value={progress} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UploadStats;

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import one from "./penguins/1.gif";
import two from "./penguins/2.gif";
import three from "./penguins/3.gif";
import four from "./penguins/4.gif";
import five from "./penguins/5.png";
import six from "./penguins/6.gif";
import "./imageResponseUI.css";

const styles = {
  image: {
    width: "100px",
    height: "100px",
  },
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const PenguinGrid = () => {
  const images = [one, two, three, four, five, six];
  const [shuffledImages, setShuffledImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [position, setPosition] = useState({ left: "0%" });

  useEffect(() => {
    setShuffledImages(shuffleArray([...images]));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % shuffledImages.length
      );
      setPosition({ left: `${Math.random() * 90}%` }); // Random position between 0% and 90%
    }, 2143); // Change image every second

    return () => clearInterval(interval);
  }, [shuffledImages]);

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
      className="grid-container pulse "
    >
      {shuffledImages.length > 0 && (
        <img
          key={currentImageIndex}
          style={{ ...styles.image, ...position }}
          src={shuffledImages[currentImageIndex]}
          alt={`penguin-${currentImageIndex + 1}`}
          className="penguinFlash fade-in"
        />
      )}
    </Grid>
  );
};

export default PenguinGrid;

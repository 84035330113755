import { useMediaQuery } from "@mui/material";

/**
 * Helper hook to determine if the viewport is mobile-sized (max-width: 1279px).
 * @returns {boolean} True if the screen width is 1279px or less, otherwise false.
 */
const useIsMobile = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return isMobile;
};

export default useIsMobile;

import React, { useEffect } from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, userDetails } from "@ui/Utils/helper";
import { isVendor } from "@ui/Utils/helper";
import { createRecord } from "@ui/ComponentUtils/blueprintAPIs";

const columns = [
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Data",
    field: "data.length",
  },
  {
    title: "Created Date",
    field: "createdAt",
    render: (row) => formatDateToRead(row?.createdAt),
  },
];

const DumpsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.dumps}
      headerTitle="Dumps"
      hideAction={{ create: true }}
      onRowClick={false}
    />
  );
};

export default DumpsList;
